"use client";
import { FC, useEffect } from "react";

import { useLocalizedRouterServer } from "@ctv/shared-core/src";

import AuthLoading from "./AuthLoading";
import { useAuth } from "./CognitoAuthContext";

export default function withAuth(Component: FC) {
  function Auth(props: any) {
    const { isAuthenticated, isLoading } = useAuth();
    const router = useLocalizedRouterServer();

    // If user is not logged in, return login component
    useEffect(() => {
      if (!isAuthenticated && !isLoading) {
        router.push("/");
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps -- only run when isAuthenticated, isLoading changes
    }, [isAuthenticated, isLoading]);

    if (isLoading || !isAuthenticated) {
      return <AuthLoading />;
    }

    // If user is logged in, return original component
    return <Component {...props} />;
  }

  return Auth;
}
